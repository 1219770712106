import {Redirect, Route, Switch} from "react-router-dom";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ResetPassword from "./auth/ResetPassword";
import ProfileForm from "./profile/ProfileForm";
import React from "react";
import {CareerType, RoutePath, RouteRestriction} from "../utils/enums";
import {ProtectedRoute} from "./auth/ProtectedRoute";
import EmployerSignUp from "./company/EmployerSignUp.js";
import ApplicationList from "./application/ApplicationList.js";
import {ApplicationDetails} from "./application/ApplicationDetails.js";
import {ApplicationForm} from "./application/ApplicationForm.js";
import CareerForm from "./career/CareerForm.js";
import CareerDetails from "./career/CareerDetails.js";
import CareerList from "./career/CareerList.js";
import StarredCareerList from "./career/StarredCareerList.js";
import CompanyForm from "./company/CompanyForm.js";
import CompanyList from "./company/CompanyList.js";
import {ProfileDetails} from "./profile/ProfileDetails.js";
import {ProfileList} from "./profile/ProfileList.js";
import {CandidateHomePage} from "./home/CandidateHomePage.js";
import {EmployerHomePage} from "./home/EmployerHomePage.js";
import {EmployerConfirmationPage} from "./EmployerConfirmationPage.js";
import AboutPage from "./home/AboutPage.js";

export const Content = () => {
    return (
        <Switch>
            <ProtectedRoute restriction={RouteRestriction.ANYONE_UNAUTHENTICATED} path={RoutePath.CANDIDATE_HOME}>
                <CandidateHomePage/>
            </ProtectedRoute>
            <ProtectedRoute restriction={RouteRestriction.ANYONE_UNAUTHENTICATED} path={RoutePath.EMPLOYER_HOME}>
                <EmployerHomePage/>
            </ProtectedRoute>
            <Route path={RoutePath.ABOUT}>
                <AboutPage/>
            </Route>
            <Route path={RoutePath.EMPLOYER_CONFIRMATION}>
                <EmployerConfirmationPage/>
            </Route>
            <ProtectedRoute restriction={RouteRestriction.ANYONE_UNAUTHENTICATED} path={RoutePath.SIGN_IN}>
                <SignIn/>
            </ProtectedRoute>
            <ProtectedRoute restriction={RouteRestriction.ANYONE_UNAUTHENTICATED} path={RoutePath.SIGN_UP}>
                <SignUp/>
            </ProtectedRoute>
            <ProtectedRoute restriction={RouteRestriction.ANYONE_UNAUTHENTICATED} path={RoutePath.EMPLOYER_SIGN_UP}>
                <EmployerSignUp/>
            </ProtectedRoute>
            <Route path={RoutePath.RESET_PASSWORD}>
                <ResetPassword/>
            </Route>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.CAREER_VIEW_ALL_PENDING_APPROVAL}>
                <CareerList listType={CareerType.PENDING}/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.CAREER_VIEW_ALL_PAUSED}>
                <CareerList listType={CareerType.PAUSED}/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.CAREER_VIEW_ALL_ARCHIVED}>
                <CareerList listType={CareerType.ARCHIVED}/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.USER_ONLY} path={RoutePath.CAREER_VIEW_ALL_STARRED}>
                <StarredCareerList/>
            </ProtectedRoute>

            <Route exact path={RoutePath.CAREER_VIEW_ALL}>
                <CareerList/>
            </Route>

            <Route exact path={RoutePath.CAREER_ADD}>
                <CareerForm/>
            </Route>
            <Route exact path={RoutePath.CAREER_VIEW()}>
                <CareerDetails/>
            </Route>
            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.CAREER_EDIT()}>
                <CareerForm/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.USER_ONLY} path={RoutePath.APPLICATION_CREATE()}>
                <ApplicationForm/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.APPLICATION_USER_DETAILS()}>
                <ApplicationDetails/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.APPLICATION_FOR_CAREER()}>
                <ApplicationList/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.USER_ONLY} path={RoutePath.APPLICATION_VIEW_ALL}>
                <ApplicationList/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.PROFILE_VIEW()}>
                <ProfileDetails/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.PROFILE_VIEW_ALL}>
                <ProfileList/>
            </ProtectedRoute>

            <ProtectedRoute path={RoutePath.PROFILE_EDIT}>
                <ProfileForm/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.COMPANY_ADD}>
                <CompanyForm/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.COMPANY_EDIT()}>
                <CompanyForm/>
            </ProtectedRoute>

            <ProtectedRoute restriction={RouteRestriction.ADMIN_ONLY} path={RoutePath.COMPANY_VIEW_ALL}>
                <CompanyList/>
            </ProtectedRoute>

            <Route>
                <Redirect to={RoutePath.CANDIDATE_HOME}/>
            </Route>
        </Switch>
    )
}