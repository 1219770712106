import * as React from 'react';

export default function ContentSection() {
    return (
        <section className="promo-content-block fix section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-12">
                        <img src="/images/candidates-banner.png" alt="banner 1"/>
                    </div>
                    <div className="col-xl-6 col-12 ps-xl-5 mt-5 mt-xl-0"
                         style={{display: 'flex', alignItems: 'center'}}>
                        <div className="block-contents ms-xl-5">
                            <div className="section-title mb-4">
                                <h2 className="wow fadeInUp">Mission</h2>
                                <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                                    At Meaningful Careers (MC), we're not just changing the landscape of talent acquisition and development; we're building a better way.
                                    <br/><br/>
                                    We believe that finding your career—or finding the right person for a career—should be straightforward and empowering. That's why we built a next-generation career platform powered by AI.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
