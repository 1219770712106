import React from 'react'
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import {userPalette} from "../../utils/theme.js";

const BasicFooter = () => {
    return (
        <Box sx={{p: 6}} component="footer">
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <a href='#'
                   style={{textDecoration: 'none', color: userPalette.main}}>
                    MC Inc
                </a>
                {` ${new Date().getFullYear()} - `}
                <a href="mailto:info@blackmaple.io"
                   style={{textDecoration: 'none', color: userPalette.main}}>
                    Contact Us
                </a>
            </Typography>
        </Box>
    )
}

export default BasicFooter
