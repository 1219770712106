import * as React from 'react';
import {PiUserFocusFill} from "react-icons/pi";
import {MdDiversity2, MdDiversity3} from "react-icons/md";

export default function AboutSectionFeatures() {
    return (
        <div className="container section-padding mtm-50">
            <div className="row  d-flex justify-content-between">
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom:'30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <PiUserFocusFill/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>Customer-Centricity</h3>
                            <p>We are dedicated to understanding and meeting the needs of our users, clients, and
                                partners. Their success is our success.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom:'30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <MdDiversity2/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>Diversity and Inclusion</h3>
                            <p>We believe that diverse teams lead to better outcomes, fostering innovation, creativity,
                                and a richer exchange of perspectives.</p>
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-md-6">
                    <div className="single-about-features">
                        <div className="custom-icon" style={{display: 'flex', marginBottom:'30px'}}>
                            <div className="icon purple" style={{margin: 'auto'}}>
                                <MdDiversity3/>
                            </div>
                        </div>
                        <div className="content">
                            <h3>Community Collaboration</h3>
                            <p>Through partnerships, philanthropy, and volunteerism, we actively cultivate a robust
                                ecosystem to drive positive economic impact in the communities we serve.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
