import * as React from 'react';
import {AiOutlineBarChart} from "react-icons/ai";
import {PiExcludeSquareDuotone} from "react-icons/pi";
import {TbGhostOff} from "react-icons/tb";
import {GiBrain} from "react-icons/gi";

export default function CandidateFeatures() {
    return (
        <section className="our-best-features-wrapper section-padding">
            <div className="container">
                <div className="text-center">
                    <div className="block-contents">
                        <div className="section-title">
                            <h2 style={{marginTop: '30px'}}>An AI-powered platform, bringing simplicity and diversity to
                                the job market, one
                                meaningful career at a time!</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <TbGhostOff/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Transparent and Active Hiring</h3>
                                <p>No ghost positions here!</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <AiOutlineBarChart/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Simple Tracking</h3>
                                <p>Our intuitive layout ensures seamless tracking and real-time updates. Say goodbye to
                                    resume limbo and duplicate submissions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <PiExcludeSquareDuotone/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Exclusive Career Resources</h3>
                                <p>From expert advice to upskilling programs, we’re here to empower you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="custom-icon">
                                <div className="icon purple">
                                    <GiBrain/>
                                </div>
                            </div>
                            <div className="content">
                                <h3>Combination of AI and Expert Guidance</h3>
                                <p>Discover the strength of a universally impressive resume with our platform - a fusion
                                    of AI and expert guidance!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
