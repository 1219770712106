import React, {useContext, useState} from "react"
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const SnackbarContext = React.createContext({})

export function useSnackbar() {
    return useContext(SnackbarContext)
}

export function SnackbarProvider({children}) {
    const [snackbars, setSnackbars] = useState([]);

    const closeSnackbar = (key) => {
        setSnackbars(currentSnackbars => currentSnackbars.filter(snackbar => snackbar.key !== key));
    };

    const openSnackbar = (message, severity = 'success') => {
        const newSnackbar = {message, severity, key: new Date().getTime()};
        setSnackbars(currentSnackbars => [...currentSnackbars, newSnackbar]);

        setTimeout(() => {
            closeSnackbar(newSnackbar.key);
        }, 10000);
    };

    const value = {openSnackbar};

    return (
        <SnackbarContext.Provider value={value}>
            {children}
            {snackbars.map((snackbar, index) => (
                <Snackbar
                    key={snackbar.key}
                    open={true}
                    autoHideDuration={null}
                    onClose={() => closeSnackbar(snackbar.key)}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    style={{bottom: (index * 60) + 10}}
                >
                    <Alert
                        onClose={() => closeSnackbar(snackbar.key)}
                        variant='filled'
                        severity={snackbar.severity}
                        sx={{width: '100%'}}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            ))}
        </SnackbarContext.Provider>
    );
}