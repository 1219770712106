import * as React from 'react';

export default function EmployerFeatures() {
    return (
        <section className="core-features-wrapper section-padding mtm-30">
            <div className="container">
                <div className="col-xl-8 offset-xl-2 text-center">
                    <div className="block-contents">
                        <div className="section-title">
                            <h2 style={{marginTop: '30px'}}>Global Hiring Made Simple!</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/diversity.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Diversity at its Core</h3>
                                <p>Unleash the power of diversity in your team by tapping into a talent marketplace that
                                    celebrates a rich tapestry of skills, experiences, and perspectives.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/global.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Global Workforce Simplified</h3>
                                <p>Our integrated Corporate Immigration Services and Legal Representation ensure that
                                    hiring talent globally is a streamlined process, providing a hassle-free experience
                                    for your organization.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-xl-3 col-12" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="single-core-feature" style={{flexGrow: 1}}>
                            <div className="icon">
                                <img src="/images/icons/personalized.svg" alt=""/>
                            </div>
                            <div className="content">
                                <h3>Personalized Approach</h3>
                                <p>Our experts bring a humanized touch to the process, ensuring you navigate the
                                    recruitment journey with confidence and achieve your hiring goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
