import * as React from 'react';
import {AiFillStar} from "react-icons/ai";
import {MdPerson, MdPerson2, MdPerson4} from "react-icons/md";

export default function EmployerTestimonials() {
    return (
        <section className="testimonial-wrapper section-padding fix">
            <div className="container">
                <div className="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                    <div className="block-contents">
                        <div className="section-title wow fadeInDown" data-wow-duration="1.2s">
                            <h2>Testimonials</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                    <MdPerson2/>
                                </div>
                            </div>
                            <div className="feedback">
                                “Hiring became significantly easier and more meaningful. The human-centered and user-friendly process allowed us to concentrate on connecting with the best candidates, making the entire process a breeze.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Medtech Client</h6>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                    <MdPerson/>
                                </div>
                            </div>
                            <div className="feedback">
                                “MC’s exceptional immigration support services and expansive global network have been pivotal in attracting top-tier talent from around the world. Their expertise not only streamlined the hiring process for critical roles but also played a crucial role in forming a world-class team with diverse perspectives for our company's success.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Edtech Client</h6>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon small">
                                    <MdPerson4/>
                                </div>
                            </div>
                            <div className="feedback">
                                “Their cost-effective approach, combined with attracting quality candidates, is a win-win for our recruitment strategy.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Fintech Startup</h6>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
