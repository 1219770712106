import * as React from 'react';
import EmployerHero from "./EmployerHero.js";
import EmployerFeatures from "./EmployerFeatures.js";
import EmployerTestimonials from "./EmployerTestimonials.js";
import IntroVideo from "./IntroVideo.js";

export const EmployerHomePage = () => {
    return (
        <span className="styles">
            <EmployerHero/>
            <EmployerFeatures/>
            <IntroVideo isCandidate={false}/>
            <EmployerTestimonials/>
        </span>
    )
};