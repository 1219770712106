import * as React from 'react';

export default function ContentSection2() {
    return (
        <section className="promo-content-block fix section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-12 mt-5 mt-xl-0"
                         style={{display: 'flex', alignItems: 'center'}}>
                        <div className="block-contents ms-xl-5">
                            <div className="section-title mb-4">
                                <h2 className="wow fadeInUp">We are in the business of people</h2>
                                <p className="wow fadeInUp pt-15" data-wow-delay=".3s">
                                    Through strategic partnerships, we not only help candidates find their perfect career but also empower people to cultivate the skills crucial for success in their chosen professions. We're not just transforming the future of work; we're creating a shared, inclusive journey where every career is a meaningful experience.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 ps-xl-5 col-12">
                        <img src="/images/employers-banner.png" alt="banner 2"/>
                    </div>
                </div>
            </div>
        </section>
    );
}
