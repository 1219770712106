import * as React from 'react';
import CandidateHero from "./CandidateHero.js";
import CandidateFeatures from "./CandidateFeatures.js";
import CandidateTestimonials from "./CandidateTestimonials.js";
import IntroVideo from "./IntroVideo.js";

export const CandidateHomePage = () => {
    return (
        <span className="styles">
            <CandidateHero/>
            <CandidateFeatures/>
            <IntroVideo/>
            <CandidateTestimonials/>
        </span>
    )
};