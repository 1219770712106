import * as React from 'react';
import {FaInstagram, FaLinkedinIn, FaTiktok} from "react-icons/fa";
import {useHistory} from "react-router-dom";
import {RoutePath} from "../../utils/enums.js";

export default function IntroFooter() {
    const history = useHistory();

    return (
        <span className="styles">
            <footer className="footer-wrapper">
                <div className="footer-widgets-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="single-footer-widget wow fadeInLeft">
                                    <div className="about-us-widget">
                                        <div className="logo">
                                            <img src="/images/white-long-logo.png"
                                                 alt="MC"
                                                 style={{width: 124, height: 70, marginBottom: '30px'}}/>
                                        </div>
                                        <p>
                                            The Untapped Talent Marketplace™.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                <div className="single-footer-widget wow fadeInLeft" data-wow-delay=".2s">
                                    <div className="widget-title">
                                        <h5>Company</h5>
                                    </div>
                                    <ul>
                                        <li onClick={() => history.push(RoutePath.ABOUT)}>
                                            <a href="#">About</a>
                                        </li>
                                        <li>
                                            <a href="mailto:info@blackmaple.io">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-wrapper">
                    <div className="container">
                        <div className="footer-bottom-content d-md-flex justify-content-between">
                            <div className="site-copyright wow fadeInUp" data-wow-delay=".2" data-wow-duration="1s">
                                <p>
                                    Copyright &copy; MC Inc - {new Date().getFullYear()}
                                </p>
                            </div>
                            <div
                                className="social-links mt-4 mt-md-0 wow fadeInUp"
                                data-wow-delay=".3"
                                data-wow-duration="1s"
                            >
                                <a href="https://www.tiktok.com/@mc.careers" target="_blank">
                                    <FaTiktok/>
                                </a>
                                <a href="https://www.instagram.com/mc_careers/" target="_blank">
                                    <FaInstagram/>
                                </a>
                                <a href="https://www.linkedin.com/company/meaningfulcareers/" target="_blank">
                                    <FaLinkedinIn/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </span>
    );
}
