import React, {useState} from 'react'
import {useAuth} from "../../contexts/AuthContext";
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Link, useHistory} from 'react-router-dom';
import {RoutePath} from "../../utils/enums";
import {LocationDropdown} from "../universal/LocationDropdown.js";
import {ImageUploadDialog} from "../universal/dialogs/ImageUploadDialog.js";
import {ResumeSelector} from "../universal/ResumeSelector.js";
import {ProfileAvatar} from "./ProfileAvatar";
import {CustomAutocomplete} from "../universal/CustomAutocomplete.js";
import {Divider} from "@mui/material";
import validator from 'validator';
import {UserManager} from "../../managers/UserManager";
import {
    isEmpty,
    logSentryException,
    newCandidateAdminNotificationEmailTemplate,
    sendGenericEmail,
    welcomeEmailTemplate
} from "../../utils/utils";
import {CustomDropdown} from "../universal/CustomDropdown.js";
import {experienceLevels, jobSettings, values, workTypes} from "../../utils/constants.js";
import {useSnackbar} from "../../contexts/SnackbarContext.js";
import {analytics} from "../../firebase-config.js";
import {logEvent} from "firebase/analytics";
import SalarySelector from "../universal/salary/SalarySelector.js";

const ProfileForm = () => {
    const [errors, setErrors] = useState('')
    const [loading, setLoading] = useState(false)
    const {currentUser, updateEmail, authenticatedUser, setAuthenticatedUser} = useAuth()
    const {openSnackbar} = useSnackbar();
    const [isImageUploadDialogOpen, setIsImageUploadDialogOpen] = useState(false);
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isNewUser = !authenticatedUser.isProfileComplete;
        let formValidationErrors = {}

        try {
            setErrors({})
            setLoading(true);

            formValidationErrors = validateForm();

            if (Object.values(formValidationErrors).indexOf(true) >= 0) {
                throw Error;
            }

            const updatedUser = {
                ...authenticatedUser,
                isProfileComplete: true,
                phone: authenticatedUser.phone.replace(/\D/g, '')
            }

            await UserManager.update(updatedUser);
            setAuthenticatedUser(updatedUser);

            history.push(RoutePath.CAREER_VIEW_ALL);

            if (isNewUser) {
                await sendEmail();
                logEvent(analytics, 'profile_submit', {
                    method: 'Profile Form Submission'
                });
            } else {
                openSnackbar('Your profile has been updated successfully!');
            }
        } catch (e) {
            const errorMessage = e.message ? e.message : 'Something went wrong. Please check the form for errors. If you need help, please contact: info@blackmaple.io';
            setErrors(formValidationErrors);
            openSnackbar(errorMessage, 'error');
            logSentryException(e, {...authenticatedUser}, 'PROFILE ERROR');
        } finally {
            setLoading(false);
        }
    };

    const sendEmail = async () => {
        try {
            await sendGenericEmail({
                to: authenticatedUser.email,
                subject: 'Welcome to Meaningful Careers (MC)!',
                content: welcomeEmailTemplate(authenticatedUser.firstName)
            });

            await sendGenericEmail({
                to: process.env.REACT_APP_MC_ADMIN_EMAIL,
                subject: `New job seeker sign up`,
                content: newCandidateAdminNotificationEmailTemplate(authenticatedUser)
            });

            openSnackbar('Welcome email has been sent successfully.');
        } catch (e) {
            openSnackbar('Welcome email failed to send.', 'error');
            logSentryException(e);
        }
    }

    const validateForm = () => {
        const errors = {};

        const salary = authenticatedUser.salary;
        const hourlyRate = authenticatedUser.hourlyRate;
        const isNotValidSalaryRange = salary !== null && (salary.length !== 2 || salary[0] > salary[1]);
        const isNotValidHourlyRateRange = hourlyRate !== null && (hourlyRate.length !== 2 || hourlyRate[0] > hourlyRate[1]);

        errors.firstName = isEmpty(authenticatedUser.firstName);
        errors.lastName = isEmpty(authenticatedUser.lastName);
        errors.email = !validator.isEmail(authenticatedUser.email);
        errors.phone = !validator.isMobilePhone(authenticatedUser.phone, ['en-US', 'en-CA']);
        errors.location = authenticatedUser.location === null;
        errors.roles = isEmpty(authenticatedUser.roles);
        errors.workStyle = isEmpty(authenticatedUser.workStyle);
        errors.experienceLevel = isEmpty(authenticatedUser.experienceLevel);
        errors.preferredWorkTypes = authenticatedUser.preferredWorkTypes.length <= 0;
        errors.values = authenticatedUser.values.length <= 0;
        errors.salary = (salary === null && hourlyRate === null) || isNotValidSalaryRange || isNotValidHourlyRateRange;
        errors.linkedInURL = isEmpty(authenticatedUser.linkedInURL) ? false : !validator.isURL(authenticatedUser.linkedInURL);
        errors.portfolioURL = isEmpty(authenticatedUser.portfolioURL) ? false : !validator.isURL(authenticatedUser.portfolioURL);
        errors.resumes = authenticatedUser.resumes.length <= 0;

        return errors;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box sx={{marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography variant="h4">Profile</Typography>
                <ProfileAvatar setIsImageUploadDialogOpen={setIsImageUploadDialogOpen}/>

                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, firstName: e.target.value})}
                        value={authenticatedUser.firstName ? authenticatedUser.firstName : ''}
                        error={errors.firstName}
                        helperText={errors.firstName ? 'First name is required.' : null}
                        required
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, lastName: e.target.value})}
                        value={authenticatedUser.lastName ? authenticatedUser.lastName : ''}
                        error={errors.lastName}
                        helperText={errors.lastName ? 'Last name is required.' : null}
                        required
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        name="email"
                        label="Email"
                        id="email"
                        value={authenticatedUser.email ? authenticatedUser.email : ''}
                        required
                        disabled
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="phone"
                        label="Phone Number (no country code)"
                        name="phone"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, phone: e.target.value})}
                        value={authenticatedUser.phone ? authenticatedUser.phone : ''}
                        error={errors.phone}
                        helperText={errors.phone ? 'Phone number is required and must be valid.' : null}
                        required
                    />
                    <LocationDropdown
                        onChange={(e, option) => setAuthenticatedUser({...authenticatedUser, location: option})}
                        value={authenticatedUser.location ? authenticatedUser.location : ''}
                        error={errors.location}
                        helperText={errors.location ? 'Location is required. Please select a valid value from the dropdown.' : null}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="roles"
                        label="Roles you are interested in"
                        name="roles"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, roles: e.target.value})}
                        value={authenticatedUser.roles ? authenticatedUser.roles : ''}
                        error={errors.roles}
                        helperText={errors.roles ? 'Roles are required.' : null}
                        required
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="portfolioURL"
                        label="Portfolio URL"
                        name="portfolioURL"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, portfolioURL: e.target.value})}
                        value={authenticatedUser.portfolioURL ? authenticatedUser.portfolioURL : ''}
                        error={errors.portfolioURL}
                        helperText={errors.portfolioURL ? 'Portfolio URL must be a valid URL.' : null}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="linkedInURL"
                        label="LinkedIn URL"
                        name="linkedInURL"
                        autoComplete="off"
                        onChange={(e) => setAuthenticatedUser({...authenticatedUser, linkedInURL: e.target.value})}
                        value={authenticatedUser.linkedInURL ? authenticatedUser.linkedInURL : ''}
                        error={errors.linkedInURL}
                        helperText={errors.linkedInURL ? 'LinkedIn URL must be a valid URL.' : null}
                    />

                    <CustomDropdown label='Preferred Work Style'
                                    value={authenticatedUser.workStyle ? authenticatedUser.workStyle : ''}
                                    onChange={(e) => setAuthenticatedUser({
                                        ...authenticatedUser,
                                        workStyle: e.target.value
                                    })}
                                    error={errors.workStyle}
                                    options={[...jobSettings, 'No Preference']}/>

                    <CustomDropdown label='Experience Level'
                                    value={authenticatedUser.experienceLevel ? authenticatedUser.experienceLevel : ''}
                                    onChange={(e) => setAuthenticatedUser({
                                        ...authenticatedUser,
                                        experienceLevel: e.target.value
                                    })}
                                    error={errors.experienceLevel}
                                    options={experienceLevels}/>

                    <SalarySelector salary={authenticatedUser.salary}
                                    setSalary={(salary) => setAuthenticatedUser({
                                        ...authenticatedUser,
                                        salary: salary,
                                        hourlyRate: null
                                    })}
                                   hourlyRate={authenticatedUser.hourlyRate}
                                   setHourlyRate={(hourlyRate) => setAuthenticatedUser({
                                       ...authenticatedUser,
                                       hourlyRate: hourlyRate,
                                       salary: null
                                   })}
                                    error={errors.salary}
                                    hideError={() => setErrors(errors => ({...errors, salary: false}))}/>

                    <CustomAutocomplete label='Preferred Work Types'
                                        selectedValues={authenticatedUser.preferredWorkTypes}
                                        onChange={(e, values) => setAuthenticatedUser({
                                            ...authenticatedUser,
                                            preferredWorkTypes: values
                                        })}
                                        error={errors.preferredWorkTypes}
                                        options={workTypes}
                                        helperText={errors.preferredWorkTypes ? 'At least one work type is required.' : null}/>

                    <CustomAutocomplete label='Values'
                                        selectedValues={authenticatedUser.values}
                                        onChange={(e, values) => setAuthenticatedUser({
                                            ...authenticatedUser,
                                            values: values
                                        })}
                                        error={errors.values}
                                        options={values}
                                        helperText={errors.values ? 'At least one value is required.' : null}/>

                    <Divider sx={{marginTop: 2}}/>

                    <ResumeSelector error={errors.resumes}/>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                        disabled={loading}
                    >
                        Save
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to={RoutePath.RESET_PASSWORD} variant="body2" style={{textDecoration: 'none'}}>
                                Change Password
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <ImageUploadDialog onClose={() => setIsImageUploadDialogOpen(false)} open={isImageUploadDialogOpen}/>
        </Container>
    )
}

export default ProfileForm