import * as React from 'react';
import {AiFillStar} from "react-icons/ai";
import {MdPerson, MdPerson2, MdPerson4} from "react-icons/md";

export default function CandidateTestimonials() {
    return (
        <section className="testimonial-wrapper section-padding fix">
            <div className="container">
                <div className="col-lg-8 col-xl-6 offset-xl-3 col-12 offset-lg-2 text-center">
                    <div className="block-contents">
                        <div className="section-title wow fadeInDown" data-wow-duration="1.2s">
                            <h2>Testimonials</h2>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson2/>
                                </div>
                            </div>
                            <div className="feedback">
                                “My post-grad job hunt turned into a success story in no time. The personalized approach to job search really made all the difference.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Adrian D.</h6>
                                    <span>Software Developer</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson/>
                                </div>
                            </div>
                            <div className="feedback">
                                “I've honed my skills and identified opportunities that align perfectly with my strengths and interests. MC's career recommendations streamlined the process, minimizing scrolling and searching, allowing me to focus more on interview preparation.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Sarah L.</h6>
                                    <span>Graphic Designer</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4">
                        <div className="single-testimoinal-box">
                            <div className="custom-icon" style={{textAlign: 'center'}}>
                                <div className="icon purple small">
                                    <MdPerson4/>
                                </div>
                            </div>
                            <div className="feedback">
                                “Thanks to expert coaching and a seamless application process, I landed my dream role perfectly aligned with my career goals.”
                            </div>
                            <div className="client-info">
                                <div className="client-name">
                                    <h6>Jade A.</h6>
                                    <span>Customer Success Manager</span>
                                </div>
                            </div>
                            <div className="rating">
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                                <AiFillStar className="icon-star"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
